import styles from "./slug.module.css";
import UseTranslation from "next-translate/useTranslation";
import {
  Language,
  LanguageUid,
  LanguageValueType,
  MenuSettings,
} from "../libs/enums";
import parse from "html-react-parser";
import { GetLanguageResource, IsNullOrEmpty } from "../libs/utils";
import { useEffect as UseEffect } from "react";
import { API_BASE_URL } from "../libs/generalSettings";
import Head from "next/head";
import LeftMenu from "../components/layout/left_menu";
import Slider from "../components/slider";
import Announcement from "../components/views/home_page/announcement";
import News from "../components/views/home_page/news/index";
const Page = ({ menu, slider, announcements, menus, news }) => {
  const { t } = UseTranslation("GetLanguageResourceResponse");

  UseEffect(() => {
    (() => {
      // let _header = document.querySelectorAll("[class*='ui-accordion-header']");
      // var _contentAll = document.querySelectorAll(
      //   "[class*='ui-accordion-content']"
      // );

      // var pttBilgiBankasiContent = document.querySelectorAll(
      //   "[id*='ptt_bilgi_bankasi_sikca_sorulan_sorular_icerik']"
      // );

      // pttBilgiBankasiContent.forEach((e) => {
      //   e.classList.add(styles.pttBilgiBankasiContent);
      // });

      // var questionContent = document.querySelectorAll("[class*='question']");
      // questionContent.forEach((e) => {
      //   e.classList.add(styles.slugAccordionTitle);
      // });

      // var questionContentText = document.querySelectorAll("[class*='text']");
      // questionContentText.forEach((e) => {
      //   e.classList.add(styles.slugAccordionTitleText);
      // });

      // var questionContentIcon = document.querySelectorAll("[class*='icon']");
      // questionContentIcon.forEach((e) => {
      //   e.classList.add(styles.slugsAccordionIcon);
      // });

      // var i = 0;
      // _header.forEach((e) => {
      //   i++;
      //   e.setAttribute("data-header-id", i);
      //   e.style.userSelect = "none";
      //   e.classList.add(styles.slugAccordion);
      // });

      // i = 0;
      // _contentAll.forEach((e) => {
      //   i++;
      //   e.setAttribute("data-content-id", i);
      //   e.classList.add(styles.collapse);
      // });

      // _header.forEach((e) => {
      //   e.addEventListener("click", () => {
      //     let _content = document.querySelector(
      //       `[data-content-id='${e.dataset.headerId}']`
      //     );
      //     _content.classList.add(styles.menuAcourdionOpenOrClose);
      //     _contentAll.forEach((content) => {
      //       if (content.dataset.contentId !== _content.dataset.contentId) {
      //         content.classList.remove(styles.menuAcourdionOpenOrClose);
      //       }
      //     });
      //   });
      // });

      const selectTypeElement = document.getElementById("sss-type");

      if (IsNullOrEmpty(selectTypeElement)) return;

      selectTypeElement.onchange = (e) => {
        if (parseInt(e.target.value) !== 0) {
          document.querySelectorAll("[data-details]").forEach((e) => {
            e.style.display = "none";
          });
        } else {
          document.querySelectorAll("[data-details]").forEach((e) => {
            e.style.display = "block";
          });
        }

        const element = document.getElementById(e.target.value);

        if (IsNullOrEmpty(element)) return;

        element.style.display = "block";
      };
    })();
  }, []);

  return (
    <>
      <Head>
        <title>
          {t(LanguageUid.TITLE)}
          {" | "}
          {menu.languageResources &&
            menu.languageResources.find(
              (x) => x.valueType === LanguageValueType.MENU
            ).value}
          {" | "}
          {menu.url === MenuSettings.SUB_HOME_PAGE &&
            t(LanguageUid.SUB_HOME_PAGE)}
        </title>
      </Head>

      {menu.url === MenuSettings.SUB_HOME_PAGE ? (
        <div className="row">
          {menu.parentMenuId === MenuSettings.NO_PARENT_MENU ? null : (
            <div className={`${styles.menuPanel} col-2 col-t-12 col-tv-12`}>
              <LeftMenu menu={menu} menus={menus} />
            </div>
          )}
          <div className={`${styles.contentPanel} col-9 col-t-11 col-tv-12`}>
            <div className={`${styles.slugSlider}`}>
              <Slider
                sliders={slider}
                panel={false}
                butons={false}
                height={400}
              />
            </div>
            <br></br>
            {announcements.length > 0 ? (
              <Announcement
                typeIsActive={false}
                announcements={announcements}
                title={true}
                viewAllUrl={`duyurular?realmId=${menu.realmId}`}
              />
            ) : (
              <News newsList={news} />
            )}
          </div>
        </div>
      ) : (
        <section>
          <div className="row">
            {menu.parentMenuId === MenuSettings.NO_PARENT_MENU ? null : (
              <div className={`${styles.menuPanel} col-2 col-t-12 col-tv-12`}>
                <LeftMenu menu={menu} menus={menus} />
              </div>
            )}
            <div className={`${styles.contentPanel} col-9 col-t-11 col-tv-12`}>
              <h1>
                {menu.languageResources &&
                  menu.languageResources.find(
                    (x) => x.valueType === LanguageValueType.TITLE
                  ).value}
              </h1>
              <div className="ck-content">
                {parse(
                  GetLanguageResource(
                    menu.languageResources,
                    LanguageValueType.CONTENT
                  )?.value ?? ""
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export async function getServerSideProps(context) {
  var sliderRequest;
  var sliderResponse;

  var announcementsRequest;
  var announcementsResponse;

  const menuRequest = await fetch(
    `${API_BASE_URL}/menu/getByLanguageResourceSlug?IsAdminPage=${false}&Slug=${
      context.query.slug
    }&languageId=${Language.LanguageId[context.locale.toUpperCase()]}`
  );

  if (menuRequest.status === 204) {
    return {
      redirect: {
        destination: "/notfound",
        permanent: false,
      },
    };
  }

  const menuResponse = await menuRequest.json();

  if (menuResponse.url === MenuSettings.SUB_HOME_PAGE) {
    sliderRequest = await fetch(
      `${API_BASE_URL}/Slider/getBySourceUid?RealmId=${
        menuResponse.realmId
      }&SourceUid=${menuResponse.uid}&IsAdminPage=${false}&LanguageId=${
        Language.LanguageId[context.locale.toUpperCase()]
      }`
    );

    if (sliderRequest.status === 204) {
      sliderResponse = [];
    } else {
      sliderResponse = await sliderRequest.json();
    }

    announcementsRequest = await fetch(
      `${API_BASE_URL}/Announcement/getByCountAndTypeAnnouncement?RealmId=${
        menuResponse.realmId
      }&count=8&LanguageId=${Language.LanguageId[context.locale.toUpperCase()]}`
    );

    announcementsResponse = await announcementsRequest.json();
  }

  const newsRequset = await fetch(
    `${API_BASE_URL}/News/getByCountAndType?count=10&LanguageId=${
      Language.LanguageId[context.locale.toUpperCase()]
    }`
  );
  const newsResponse = await newsRequset.json();

  return {
    props: {
      menu: menuResponse,
      slider: sliderResponse ?? [],
      announcements: announcementsResponse ?? [],
      news: newsResponse ?? [],
    },
  };
}

export default Page;
